var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row mb-3" }, [
      _c("h5", { staticClass: "col-8 mt-3" }, [
        _vm._v("Agenda en evenementen"),
      ]),
      _c(
        "div",
        { staticClass: "col-4 mt-3" },
        [
          _c(
            "b-form-checkbox",
            {
              attrs: { name: "check-button", switch: "" },
              on: { change: _vm.filterChanged },
              model: {
                value: _vm.showOld,
                callback: function ($$v) {
                  _vm.showOld = $$v
                },
                expression: "showOld",
              },
            },
            [_vm._v(" Toon verlopen items ")]
          ),
          _c(
            "b-form-checkbox",
            {
              attrs: { name: "check-button", switch: "" },
              on: { change: _vm.filterChanged },
              model: {
                value: _vm.showOnlySignUp,
                callback: function ($$v) {
                  _vm.showOnlySignUp = $$v
                },
                expression: "showOnlySignUp",
              },
            },
            [_vm._v(" Toon alleen evenementen van mij en mijn familie ")]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col mb-3" }, [
        _vm.error
          ? _c(
              "div",
              { staticClass: "alert alert-danger", attrs: { role: "alert" } },
              [
                _vm._v(
                  " There was an error when loading the events, please try again later. "
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col mb-3" }, [
        _vm.loaded && _vm.eventGroups == null
          ? _c(
              "div",
              { staticClass: "alert alert-warning", attrs: { role: "alert" } },
              [_vm._v(" There are no events to show. ")]
            )
          : _vm._e(),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "row eventspage" },
      _vm._l(_vm.eventGroups, function (eventGroup) {
        return _c(
          "div",
          {
            key: eventGroup.month + "_" + eventGroup.year,
            staticClass: "col-12 col-md-4 mb-4",
          },
          [
            _c("h6", { staticClass: "text-muted" }, [
              _vm._v(
                _vm._s(_vm.getMonthName(eventGroup.month)) +
                  " " +
                  _vm._s(eventGroup.year)
              ),
            ]),
            _c(
              "div",
              { staticClass: "list-group" },
              _vm._l(eventGroup.events, function (event) {
                return _c(
                  "router-link",
                  {
                    key: event.id,
                    staticClass: "list-group-item list-group-item-action",
                    attrs: { to: "/events/" + event.id },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex w-100 justify-content-between" },
                      [
                        _c("h6", { staticClass: "mb-1" }, [
                          _vm._v(_vm._s(event.title)),
                        ]),
                      ]
                    ),
                    _vm._l(event.possibleAttendees, function (attendee) {
                      return _c("div", { key: attendee.id }, [
                        _c("small", { staticClass: "text-small" }, [
                          _c("i", {
                            staticClass: "fa",
                            class: _vm.getAttendeeIcon(attendee),
                          }),
                          _vm._v(" " + _vm._s(attendee.firstName) + " "),
                        ]),
                      ])
                    }),
                    _c("small", [
                      _vm._v(
                        _vm._s(_vm.getStartDate(event.startDate)) +
                          " / " +
                          _vm._s(_vm.getEndDate(event.endDate, event.startDate))
                      ),
                    ]),
                    _c(
                      "span",
                      {
                        staticClass: "badge badge-light badge-pill float-right",
                      },
                      [_c("i", { staticClass: "fas fa-chevron-right" })]
                    ),
                  ],
                  2
                )
              }),
              1
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }