



























































import { Action, Getter } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { EventAttendee } from '../models/event-attendee.model';
import { AttendeeStatus } from '../models/attendee-status.enum';
import { EventGroup } from '../models/event-group.model';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import dayjs from 'dayjs';

@Component
export default class Events extends Vue {
  awaitingSearch = false;
  loaded = false;
  error = false;
  showOldEvents = false;
  showOnlySignUpEvents = false;

  @Action
  setLoading!: (loading: boolean) => Promise<void>
  @Action
  loadEvents!: (payload: { showOld: boolean, showOnlySignUp: boolean }) => Promise<void>

  @Getter
  eventGroups!: EventGroup[];


  async created(): Promise<void> {
    await this.setLoading(true);
    try {
      await this.loadEvents(this.query);
      this.loaded = true;
    } catch {
      ToastHelper.showError(this.$bvToast, `Failed to load events!`);
      this.error = true;
    } finally {
      await this.setLoading(false);
    }
  }

  async filterChanged(): Promise<void> {
    if (this.awaitingSearch) {
      return;
    }

    await this.setLoading(true);
    if (!this.awaitingSearch) {
      setTimeout(async () => {
        await this.loadEvents(this.query)
        await this.setLoading(false);
        this.awaitingSearch = false
      }, 300)
    }
    this.awaitingSearch = true
  }

  getMonthName(month: number): string {
    const monthName = dayjs().month(month -1).format('MMMM');
    return monthName.charAt(0).toUpperCase() + monthName.slice(1);
  }
  getStartDate(date: string): string {
    return dayjs(date).format('D MMM HH:mm');
  }
  getEndDate(date: string, dateToCheckIfNotSameDay: string): string {
    const parsedDateToCheckIfNotSameDay = dayjs(dateToCheckIfNotSameDay)
    const parsedDate = dayjs(date)

    if (parsedDate.date() === parsedDateToCheckIfNotSameDay.date()) {
      return parsedDate.format('HH:mm')
    } else {
      return parsedDate.format('D MMM HH:mm')
    }
  }
  getAttendeeIcon(attendee: EventAttendee): string {
    if (!attendee.status) {
      return 'fa-user-plus text-info'
    } else if (attendee.status === AttendeeStatus.Rejected) {
      return 'fa-times text-danger'
    } else if (attendee.status === AttendeeStatus.AwaitingPayment) {
      return 'fa-envelope-open-dollar text-warning'
    } else {
      return 'fa-check text-success'
    }
  }

  get showOld(): boolean {
    return this.showOldEvents;
  }
  set showOld(value: boolean) {
    this.showOldEvents = value;
    this.filterChanged();
  }

  get showOnlySignUp(): boolean {
    return this.showOnlySignUpEvents;
  }
  set showOnlySignUp(value: boolean) {
    this.showOnlySignUpEvents = value;
    this.filterChanged();
  }

  get query():{ showOld: boolean, showOnlySignUp: boolean } {
    return { showOld: this.showOld, showOnlySignUp: this.showOnlySignUp };
  }
}
